import React from 'react'
import {graphql} from 'gatsby'
import Helmet from 'react-helmet'
import {
	mapEdgesToNodes,
	filterOutDocsWithoutSlugs,
	filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import ProjectPreviewGrid from '../components/project-preview-grid'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
	query WorkPageQuery {
		site: sanitySiteSettings(_id: {regex: "/(drafts.|)siteSettings/"}) {
			title
			description
			keywords
			facebook
			instagram
			twitter
			shareImage {
				asset {
					_id
				}
				crop {
					top
					right
					left
					bottom
					_type
					_key
				}
				hotspot {
					y
					height
					_type
					_key
					width
					x
				}
			}
		}
		projects: allSanityProject(sort: {fields: [category___id, publishedAt], order: DESC}, filter: {slug: {current: {ne: null}}, publishedAt: {ne: null}, isTest: {ne: true}}) {
			edges {
				node {
					id
					primaryImage {
						crop {
							_key
							_type
							top
							bottom
							left
							right
						}
						hotspot {
							_key
							_type
							x
							y
							height
							width
						}
						asset {
							_id
						}
						alt
					}
					shapeImage {
						asset {
							_id
						}
					}
					title
					slug {
						current
					}
					category {
						_id
						catColor {
							color {
								hex
							}
						}
						catColor {
							color {
								hex
							}
						}
					}
					shape {
						key
					}
				}
			}
		}
	}
`

const WorkPage = (props) => {
	const {data, errors} = props

	if (errors) {
		return (
			<Layout>
			<GraphQLErrorList errors={errors} />
			</Layout>
		)
	}

	const site = (data || {}).site
	const projectNodes = (data || {}).projects
		? mapEdgesToNodes(data.projects)
			.filter(filterOutDocsWithoutSlugs)
			.filter(filterOutDocsPublishedInTheFuture)
		: []

	if (!site) {
		throw new Error(
			'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
		)
	}

	return (
		<Layout>
			{/* TODO: fix SEO data flow */}
			<SEO title={site.title} description={site.description} keywords={site.keywords} />
			<Container>
				<h1 hidden>Our Work</h1>
				{projectNodes && (
					<ProjectPreviewGrid
						nodes={projectNodes}
					/>
				)}
			</Container>
		</Layout>
	)
}

export default WorkPage
