import {Link} from 'gatsby'
import React from 'react'
import ProjectPreview from './project-preview'
import MaskDefs from './shapes/masks'

import styles from './project-preview-grid.module.css'

const ProjectPreviewGrid = (props) => {
	return (
		<div className={styles.root}>
			<MaskDefs />
			<ul className={styles.grid}>
				{props.nodes &&
					props.nodes.map(node => (
						<li key={node.id}>
							<ProjectPreview {...node} />
						</li>
				))}
			</ul>
		</div>
	)
}

ProjectPreviewGrid.defaultProps = {
	nodes: [],
}

export default ProjectPreviewGrid
